import styled, { css } from 'styled-components';
import { Section } from '../../../forMain/Section';

export const LastScreenSection = styled(Section)(
  ({ theme: { tablet, laptop } }) => css`
    padding-bottom: 120px;
    padding-top: 40px;

    ${tablet()} {
      padding-bottom: 140px;
      padding-top: 80px;
    }

    ${laptop()} {
      padding-bottom: 170px;
    }
  `
);
