export const data = [
  {
    title: 'Automate image optimization',
    text: 'Eliminate performance issues and bottlenecks, with zero tedious work.',
  },
  {
    title: 'Improve Core Web Vitals',
    text: 'All-around performance, from speed tests to Largest Contentful Paint.',
  },
  {
    title: 'Improve mobile performance',
    text: 'Automate responsive images and meet mobile-friendly demand.',
  },
  {
    title: 'Simplify your codebase',
    text: 'Lightweight library for all image ops and optimizations.',
  },
  {
    title: 'Slash infrastructure costs',
    text: 'One global CDN for both image delivery and optimizations.',
  },
  {
    title: 'Accelerate PageSpeed',
    text: 'Next-level your SEO performance and overall website efficiency.',
  },
];
