import React from 'react';

import { SiteLayout } from '~/src/components/Layout/SiteLayout';
import {
  FirstScreen,
  ServicesScreen,
  WorkflowScreen,
  ImageCompressionScreen,
  QualityScreen,
  LastScreen,
} from '~/src/components/forSpeedOptimization';

export const frontmatter = {
  title: 'Pagespeed optimization',
  description:
    'Drastically improve website speed and Core Web Vitals with feature-complete image optimization right from your CDN.',
};

const SpeedOptimization = ({ location }) => {
  return (
    <SiteLayout meta={frontmatter} pathName={location.pathname} isDark>
      <FirstScreen />
      <ServicesScreen />
      <WorkflowScreen />
      <ImageCompressionScreen />
      <QualityScreen />
      <LastScreen />
    </SiteLayout>
  );
};

export default SpeedOptimization;
