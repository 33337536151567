import styled, { css } from 'styled-components';

export const ServicesContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 70px;

    ${tablet()} {
      grid-gap: 100px;
    }

    ${laptop()} {
      grid-gap: 130px;
    }
  `
);
