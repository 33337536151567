import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { ThemesEnum } from '@uc-common/theme';
import { SubText, CTAButton } from '../../../forMain/Typography';
import circleBg from '../../assets/circle.svg';

export const Heading = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 19px;
    color: #ffffff;
    max-width: 400px;
    display: inline-flex;
    flex-direction: column;

    ${tablet()} {
      font-size: 52px;
      margin-bottom: 30px;
      max-width: 670px;
    }

    ${laptop()} {
      font-size: 60px;
      max-width: 780px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 900px;
    }
  `
);

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledSection = styled.section.attrs({
  'data-navbar-theme': ThemesEnum.DARK,
})(
  ({ theme: { tablet, laptop, desktop } }) => css`
    background: url(${circleBg}) no-repeat center 30px;
    padding-top: 90px;
    background-size: contain;
    overflow: hidden;

    ${tablet()} {
      padding-top: 173px;
      background-position-y: 10px;
      background-size: 586px auto;
    }

    ${laptop()} {
      padding-top: 160px;
      background-position-y: 15px;
    }

    ${desktop()} {
      padding-top: 170px;
      background-position-y: 0;
      background-size: 684px auto;
    }
  `
);

export const StyledSubText = styled(SubText)(
  ({ theme: { laptop } }) => css`
    max-width: 640px;

    ${laptop()} {
      max-width: 830px;
    }
  `
);

export const FirstScreenButton = styled(CTAButton)`
  margin-top: 30px;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;
