import styled, { css } from 'styled-components';
import { GradientSuperHeading } from '~/src/components/Typography';
import { Integrations } from '~/src/components/forMain/Uploading/Integrations';

export const QualityScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(141.01% 172.5% at 0% 0%, #b951be 0%, #9365fd 84.45%);
`;

export const QualityScreenFeaturesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const QualityScreenIntegrationBlockContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 50px;

    ${tablet()} {
      margin-top: 70px;
    }

    ${laptop()} {
      margin-top: 90px;
    }
  `
);

export const QualityScreenIntegrationsTitle = styled.h5(
  ({ theme: { tablet, laptop } }) => css`
    display: block;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 40px auto 15px;
    color: #838f9a;

    ${tablet()} {
      font-size: 12px;
      margin-top: 60px;
    }

    ${laptop()} {
      font-size: 14px;
    }
  `
);

export const QualityScreenScreenIntegrations = styled(Integrations)(
  ({ theme: { tablet } }) => css`
    margin-top: 15px;
    padding-bottom: 30px;

    ${tablet()} {
      padding-bottom: 60px;
      margin-top: 35px;
    }
  `
);
