import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';
import { Link } from '~/src/components/Link';

import { CompressionScreenOperationsText } from '~/src/components/forImageCDN/CompressionScreen/styles/CompressionScreen.styles';

export const ImageCompressionScreenOperationsText = styled(CompressionScreenOperationsText)`
  &::before {
    background: none;
  }
`;

export const ImageCompressionScreenSection = styled(Wrapper)`
  padding-top: 100px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageCompressionScreenDescription = styled.p(
  ({ theme: { desktop } }) => css`
    font-size: 18px;
    color: #bbc4d2;
    line-height: 1.6;
    text-align: left;
    max-width: 830px;
    margin: 20px auto 0 auto;

    p:not(:first-child) {
      margin-top: 30px;
    }

    ${desktop()} {
      font-size: 20px;
    }
  `
);

export const ImageCompressionScreenInlineLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: border-color 0.1s ease-in;

  &:hover {
    border-color: #ffffff;
  }
`;
