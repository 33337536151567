import React from 'react';

import { Container } from '@uc-common/container';

import { SectionWrapper } from '~/src/components/forFileUploader/styles';
import { Section } from '~/src/components/forMain/Section';
import { Service, Advantages } from '~/src/components/forSolutions';
import { ServicesContainer } from './styles/ServicesScreen.styles';
import { SERVICES } from './data';

export const ServicesScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <ServicesContainer>
        {SERVICES.map(
          (
            { superHeading, heading, paragraph, options = [], imageUuid, imageAlt, imageOptions },
            index
          ) => (
            <Service
              index={index + 1}
              key={heading}
              superHeading={superHeading}
              heading={heading}
              paragraph={paragraph}
              options={options}
              imageUuid={imageUuid}
              imageAlt={imageAlt}
              imageOptions={imageOptions}
            />
          )
        )}
      </ServicesContainer>
      <SectionWrapper>
        <Advantages advantage="Simplify education compliance thanks to our secure platform" />
      </SectionWrapper>
    </Container>
  </Section>
);
