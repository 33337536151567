import React from 'react';

import { Container } from '@uc-common/container';

import { Section } from '~/src/components/forMain/Section';
import { FeatureCard } from '~/src/components/forSolutions';
import { IntegrationBlock } from '~/src/components/forImageCDN/IntegrationScreen';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import {
  QualityScreenGradientSuperHeading,
  QualityScreenFeaturesGrid,
  QualityScreenIntegrationBlockContainer,
  QualityScreenIntegrationsTitle,
  QualityScreenScreenIntegrations,
} from './styles/QualityScreen.styles';
import { data } from './data';

export const QualityScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <QualityScreenGradientSuperHeading>
        Quality of life for developers
      </QualityScreenGradientSuperHeading>
      <SolutionsSubHeading>Reduce both page load time and development time</SolutionsSubHeading>

      <QualityScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </QualityScreenFeaturesGrid>

      <QualityScreenIntegrationBlockContainer>
        <IntegrationBlock />
      </QualityScreenIntegrationBlockContainer>

      <QualityScreenIntegrationsTitle>Uploadcare is stack agnostic</QualityScreenIntegrationsTitle>
      <QualityScreenScreenIntegrations />
    </Container>
  </Section>
);
