import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import { Clients } from '~/src/components/Clients';
import { Link } from '../../Link';
import { GradientSuperHeading } from '../../Typography';
import {
  Heading,
  StyledContainer,
  StyledSection,
  StyledSubText,
  FirstScreenButton,
} from './styles/FirstScreen.styles';

export const FirstScreen = ({ btnSignUpProps = {} }) => (
  <StyledSection>
    <StyledContainer>
      <GradientSuperHeading>Pagespeed optimization</GradientSuperHeading>
      <Heading>Website media speed optimization</Heading>

      <StyledSubText>
        Drastically improve website speed and Core Web Vitals with feature-complete image
        optimization right from your CDN.
      </StyledSubText>

      <FirstScreenButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_0"
        {...btnSignUpProps}
      >
        Start Building for Free
      </FirstScreenButton>

      <Clients />
    </StyledContainer>
  </StyledSection>
);
