export const SERVICES = [
  {
    heading: 'Reduce website lag and lower latency',
    paragraph:
      'Uploadcare CDN features best-in-class global availability and low latency, securing the highest baseline for website performance with no server overload.',
    imageUuid: '7c4d9755-f27a-4a4c-8009-9b0393506279',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Reduce website lag and lower latency',
  },
  {
    heading: 'Optimize images natively from CDN',
    paragraph:
      'The CDN does more than broad coverage, with powerful built-in workflows to compress images on the fly for each user device and locale.',
    imageUuid: 'ae42d557-2b24-441b-9cc0-314beff3e1b7',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Optimize images natively from CDN',
    revert: true,
  },
];
