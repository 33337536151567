import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { Button, ButtonTypeEnum } from '@uc-common/button';

import { Link } from '~/src/components/Link';
import {
  CompressionScreenImagesComparator,
  CompressionScreenAvailableOperations,
  CompressionScreenOperationsList,
  CompressionScreenOperationsHeading,
  CompressionScreenOperationsButtons,
} from '~/src/components/forImageCDN/CompressionScreen/styles/CompressionScreen.styles';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { Quote } from '~/src/components/forSolutions';
import { Section } from '~/src/components/forMain/Section';
import {
  ImageCompressionScreenDescription,
  ImageCompressionScreenOperationsText,
  ImageCompressionScreenInlineLink,
} from './styles/ImageCompressionScreen.styles';

export const ImageCompressionScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>Best in class image compression</SolutionsSubHeading>
      <ImageCompressionScreenDescription>
        <p>
          Uploadcare actively contributes to{' '}
          <ImageCompressionScreenInlineLink
            to="https://github.com/uploadcare/pillow-simd"
            target="_blank"
          >
            Pillow-SIMD
          </ImageCompressionScreenInlineLink>
          , a version of the Pillow library highly optimized for x86 architectures. It outperforms
          the original by a factor of 4-6, reducing image size by over 80% with no visible quality
          loss.
        </p>

        <p>
          <ImageCompressionScreenInlineLink to="/customers/shogun/">
            Check
          </ImageCompressionScreenInlineLink>{' '}
          how we saved Shogun $200,000 worth of bandwidth costs on image delivery alone.
        </p>
      </ImageCompressionScreenDescription>

      <CompressionScreenImagesComparator />
      <CompressionScreenAvailableOperations>
        <ImageCompressionScreenOperationsText>
          <CompressionScreenOperationsList>
            auto image resizing, content-aware crop, image quality, dynamic images, native webp
            support, hero images size reduction.{' '}
          </CompressionScreenOperationsList>
          <CompressionScreenOperationsHeading>
            Max compression, no quality loss.
          </CompressionScreenOperationsHeading>
        </ImageCompressionScreenOperationsText>
        <CompressionScreenOperationsButtons>
          <Button
            as={Link}
            to="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            data-analytics="btn_signup_1"
          >
            Start Building for Free
          </Button>
        </CompressionScreenOperationsButtons>
      </CompressionScreenAvailableOperations>
    </Container>
    <Quote
      logoSrc={logos[BuzzLogoIdEnum.MOZILLA].src}
      logoAlt="Mozilla logo"
      quoteText="It's the most painless, enjoyable flow out there. Awesome product. We all love Uploadcare dearly."
      avatarSrc="https://ucarecdn.com/6bf9903e-584d-459c-b621-819a5bf4910a/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Chris Van Wiemeersch | UX Engineer"
    />
  </Section>
);
