import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { SmallCard, Quote } from '~/src/components/forSolutions';
import { Section } from '../../forMain/Section';
import { WorkflowScreenCardsContainer } from './styles/WorkflowScreen.styles';

export const WorkflowScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>
        One-stop workflow for website performance optimization
      </SolutionsSubHeading>
      <WorkflowScreenCardsContainer>
        <SmallCard
          title="Intuitive URL API"
          text="Deliver ideal image size and image quality via URL parameters – even if you’re storing images elsewhere."
          src="https://ucarecdn.com/0e928b99-e406-4f5e-9be4-edb80fe78ee5/upload.svg"
        />
        <SmallCard
          title="Smart image resizing"
          text="Dynamic images without manual work, with native support for HEIC, WEBP, and AVIF formats."
          src="https://ucarecdn.com/8f9fcd00-0021-4e1f-ae4e-50c6b2a10aef/secure.svg"
        />
        <SmallCard
          title="Browser detection"
          text="Minimize lag and improve experience by delivering optimal media formats to each visitor's user agent."
          src="https://ucarecdn.com/b62b40c2-f8ba-4e10-a6cf-2e7771315bc7/deliver.svg"
        />
        <SmallCard
          title="AWS Nitro System"
          text="Rapid-fire image processing with new compute-optimized Amazon EC2 C6a instances."
          src="https://ucarecdn.com/862c7c49-b84e-4e6a-8f53-b4b84341f2cd/store.svg"
        />
      </WorkflowScreenCardsContainer>
    </Container>
    <Quote
      logoSrc={logos[BuzzLogoIdEnum.CHATRA].src}
      logoAlt="Chatra"
      quoteText="In-house file infrastructure? No thank you. With Uploadcare, we save 40 hours of our developers time per week and decrease the total cost of ownership (TCO) on infrastructure development. More to ask?"
      avatarSrc="https://ucarecdn.com/2c2ad30d-b5cf-4c08-bcd4-6f088424844d/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Yakov Karda | CEO & Co-founder"
    />
  </Section>
);
